import React                     from 'react';
import { GiDiamondRing, GiShop } from 'react-icons/gi';
import { Center, Button }        from '@chakra-ui/react';
import Breakpoint                from 'react-socks';

import Heading                   from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer                    from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper                   from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import LiveAnnouncements
                                 from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import RandomBrandHeaderImages
                                 from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import BrandsDisplay             from '@interness/theme-sonora/src/components/Display/BrandsDisplay';
import CallToAction              from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
import Link                      from '@interness/web-core/src/components/elements/Link/Link';
import RandomSquareGalleryImages from '@interness/brands-addon/src/components/media/RandomSquareGalleryImages';
import Mounted                   from '@interness/web-core/src/components/structure/Mounted/Mounted';

const IndexPage = (props) => {
  return (
    <>
      <Mounted>
        <Breakpoint small down>
          <RandomSquareGalleryImages/>
        </Breakpoint>
        <Breakpoint medium up>
          <RandomBrandHeaderImages/>
        </Breakpoint>
      </Mounted>
      <Wrapper>
        <Spacer height={20}/>
        <Center>
          <Button sx={{ textDecoration: 'none' }} colorScheme="brand" as={Link} mx="5px" external
                  to="https://trauringstudio-stauss.de">Trauringstudio</Button>
          <Button colorScheme="brand" variant="outline" sx={{ textDecoration: 'none' }} as={Link} m="5px"
                  to="/terminvereinbarung">Terminvereinbarung</Button>
        </Center>
        <section>
          <Spacer/>
          <LiveAnnouncements/>
          <Spacer/>
          <Heading icon={<GiDiamondRing/>} subtitle={'Herzlich Willkommen'}>Juwelier Stauss</Heading>
          <Spacer height={20}/>
          <span style={{ textAlign: 'justify' }}>
              <p>Seien Sie herzlich willkommen bei Stauss, Uhren & Schmuck in Rottweil, der ältesten Stadt Baden-Württembergs. Stauss, Uhren & Schmuck ist seit vielen Jahren ein Begriff für außergewöhnlichen Schmuck und erlesene Uhren, sowie exzellenten Service.</p> 
              <p>In unseren moderneren Geschäftsräumen in der Rottweiler Innenstadt möchten wir Ihnen ein noch schöneres Einkaufserlebnis ermöglichen und legen größten Wert auf persönliche Beratung.</p> 
              <p>Freundliche und fachlich kompetente Beratung sind für uns eine Selbstverständlichkeit. </p>
              <p>Gerne widmen wir Ihnen unsere Aufmerksamkeit für Ihre individuellen Wünsche bei Uhren und Schmuck. In unserem Trauringstudio nehmen wir uns die Zeit für Sie, damit Sie Ihre ganz persönlichen Trauringe und Eheringe finden, inklusiver besonderer Gravuren. </p>
              <p>Wir kaufen Ihr Altgold zu fairen und tagesaktuellen Preisen an.</p>
              <p>BIS BALD IN ROTTWEIL <br/>
              IHRE ULRIKE STAUSS</p>
            </span>
        </section>
        <Spacer/>
        <section>
          <Heading icon={<GiShop/>} tag={'h2'}>Aktuelle Kollektionen</Heading>
          <BrandsDisplay order={['jewelry', 'watches']}/>
        </section>
        <Spacer/>
        <CallToAction/>
        <Spacer/>
      </Wrapper>
    </>
  )
};

export default IndexPage;
